import { css } from 'styled-components'
import { radius } from '@Mixins'

// eslint-disable-next-line
export default (params) => {
  let radiusVal = '10px'
 
  if(params) radiusVal = params.radius

  return css`
    padding: 20px;
    background: rgb(255, 255, 255);
    box-shadow: rgba(255, 255, 255, 0.1) 0px 0px 0px 1px, rgba(14, 30, 37, 0.12) 0px 2px 4px 0px;
    ${radius({ value: radiusVal })};
    margin-bottom: 4px;
  `
} 