import { css } from 'styled-components'

// eslint-disable-next-line
export default (params) => {
  let topLeft = '0px'
  let topRight = '0px'
  let bottomRight = '0px'
  let bottomLeft = '0px'

  if(params.topLeft) topLeft = params.topLeft
  if(params.topRight) topRight = params.topRight
  if(params.bottomRight) bottomRight = params.bottomRight
  if(params.bottomLeft) bottomLeft = params.bottomLeft

  if (params.value) {
    topLeft = params.value
    topRight = params.value
    bottomRight = params.value
    bottomLeft = params.value
  }

  return css`
    border-radius: ${topLeft} ${topRight} ${bottomRight} ${bottomLeft};
  `
} 