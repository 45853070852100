import React from 'react';
import styled from 'styled-components'
import styles from './styles'

const Element = styled.div`${styles}`
const Shape = ({ children, size, background, color, rotation, className }) => {
  let classNames = size
  if (className) classNames = `${classNames} ${className}`
  
  return (
    <Element
      className={classNames}
      background={background}
      color={color}
      rotation={rotation}
    >
      {children}
    </Element>
  )
}

Shape.defaultProps = {
  size: 'md', // 'xl' | 'lg' | 'md' | 'sm' | 'xs',
  background: 'green',
  color: 'white',
  rotation: '0deg',
}

export default Shape