import { css } from 'styled-components'

export default css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${props => props.background};
  color: ${props => props.color};
  border-top-left-radius: 470px;
  border-top-right-radius: 570px;
  border-bottom-right-radius: 430px;
  border-bottom-left-radius: 580px;
  transform: rotate(${props => props.rotation});
  & > * {
    transform: rotate(-${props => props.rotation});
  }
  opacity: 0.88;
  
  &:hover {
    opacity: 1;
  }

  &.xl {
    width: 420px;
    height: 420px;
  }
  &.lg {
    width: 220px;
    height: 220px;
  }
  &.md {
    width: 140px;
    height: 140px;
    font-size: 60px;
  }
  &.sm {
    width: 80px;
    height: 80px;
    font-size: 30px;
  }
  &.xs {

  }
`