import styled from 'styled-components'

export const Block = styled.div`
    width: 100%;
    max-width: 460px;
    min-height: 460px;
    //background: #1cd5b4;
    //background: #1cb3d5;
    background: #f7f7f7;
    color: white;
    border-radius: 5px;
    padding: 40px;
    position: relative;
    //top: -240px;
    top: -380px;
    margin-left: -35px;

    border-top-left-radius: 470px;
    border-top-right-radius: 570px;
    border-bottom-right-radius: 430px;
    border-bottom-left-radius: 580px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    transform: rotate(-2deg);

    /*img {
        max-width: 80%;
        margin-bottom: 0;
        opacity: 0.8;
    }*/

    .photo {
        width: 80%;
        height: 80%;
        margin-bottom: 0;
        opacity: 0.8;
    }

    @media(max-width: 1200px) {
        max-width: 360px;
        min-height: 360px;
        top: -320px;
        margin-left: -75px;
    }

    @media(max-width: 767px) {
        max-width: 240px;
        min-height: 240px;
        top: -210px;
        margin-left: -25px;

        .photo {
            width: 100%;
            height: 100%;
        }
    }

    /* variant styling */
    &.variant {
        max-width: 280px;
        min-height: 280px;
        margin-top: -90px;

        @media(max-width: 1200px) {
            max-width: 160px;
            min-height: 160px;
            padding: 10px;
            margin-top: 10px;
            margin-left: -16px;
        }
        @media(max-width: 830px) {
            max-width: 120px;
            min-height: 120px;
            padding: 10px;
            margin-top: 10px;
            margin-left: -13px;
        }
        @media(max-width: 767px) {
            max-width: 120px;
            min-height: 120px;
            padding: 10px;
            margin-top: -124px;
        }
        @media(max-width: 420px) {
            margin-top: -112px;
            max-width: 90px;
            min-height: 90px;
            padding: 10px;
        }
    }
`