import styled, { css } from 'styled-components'
import { card } from '@Mixins'

export default css`
  #pet-name-avatar {
    border-top-left-radius: 470px;
    border-top-right-radius: 570px;
    border-bottom-right-radius: 430px;
    border-bottom-left-radius: 580px;
  }
  h2 {
    font-size: 2.2rem;
    color: #4c95c2;
    margin-bottom: 12px;
  }
  h3 {
    font-size: 2rem;
    color: #636363;
    margin-bottom: 12px;
    margin-top: 12px;
  }
  .rehab-period {
    p {
      max-width: 100%;
    }
  }
  &#story {
    .rehab-container {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
  .arnostek-container {
    @media(max-width: 1200px) {
      width: 830px;
    }
    @media(max-width: 830px) {
      width: 550px;
    }
    @media(max-width: 580px) {
      width: 400px;
    }
    @media(max-width: 400px) {
      width: 300px;
    }
  }
  .mobile-short-description {
    display: none;
    @media(max-width: 830px) {
      display: block;
      font-weight: bold;
      text-align: center;
      max-width: 470px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      font-size: 16px;
      margin-bottom: 60px;
      p {
        color: #25a0aa;
      }
    }
  }
`

export const VideoSection = styled.section`
  ${card({ radius: '35px' })};
  margin-top: -440px;
  position: relative;
  z-index: 150;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 660px;

  iframe {
    width: 100%;
    height: 100%;
    border-radius: 35px;
  }

  @media(max-width: 1200px) {
    width: 830px;
    height: 493px;
    margin-top: -365px;
  }

  @media(max-width: 830px) {
    width: 550px;
    height: 327px;
    margin-top: -365px;
  }

  @media(max-width: 767px) {
    margin-top: -255px;
  }

  @media(max-width: 580px) {
    padding: 10px;
    width: 400px;
    height: 233px;
    border-radius: 20px;

    iframe {
      border-radius: 20px;
    }
  }

  @media(max-width: 400px) {
    padding: 10px;
    width: 300px;
    height: 177px;
    border-radius: 20px;
    margin-top: -246px;

    iframe {
      border-radius: 20px;
    }
  }
`

export const Controls = styled.div`
  position: absolute;
  right: 20px;
  top: -60px;
  width: 300px;
  height: 80px;
  display: flex;
  justify-content: space-between;
`

export const Control = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background: white;
  border-top-left-radius: 470px;
  border-top-right-radius: 570px;
  border-bottom-right-radius: 430px;
  border-bottom-left-radius: 580px;
  cursor: pointer;
  opacity: 0.8;
  &:hover {
    opacity: 0.9;
  }
  &.active {
    opacity: 1;
  }
  svg {
    position: relative;
  }

  &.first {
    svg {
      top: -4px;
    }
  }
  &.second {
    transform: rotate(-40deg);
    svg {
      transform: rotate(40deg);
      top: -2px;
      left: 3px;
    }
  }
  &.third {
    transform: rotate(-80deg);
    svg { 
      transform: rotate(80deg);
      right: -8px;
    }
  }
  svg {
    font-size: 44px;
  }
`

export const TopBlock = styled.div`
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  z-index: 150;
  position: relative;
  padding-left: 280px;
  margin-top: 60px;
  color: white;
  opacity: 0.88;

  h1 {
    font-size: 3.5em;
    margin-bottom: 10px;

    @media(max-width: 1200px) {
      font-size: 3em;
      margin-bottom: 0;
    }
    @media(max-width: 830px) {
      font-size: 2.5em;
    }
    @media(max-width: 400px) {
      font-size: 28px !important;
    }
  }
  p {
    margin-bottom: 0;
    font-size: 1.25em;
    @media(max-width: 1200px) {
      font-size: 18px;
    }
    @media(max-width: 830px) {
      display: none;
    }
  }

  @media(max-width: 1200px) {
    width: 830px;
    padding-left: 160px;
    margin-top: 47px;
  }
  @media(max-width: 830px) {
    width: 550px;
    padding-left: 125px;
    margin-top: 75px;
  }
  @media(max-width: 767px) {
    margin-top: 60px;
  }
  @media(max-width: 580px) {
    width: 400px;
    padding-left: 130px;
    margin-top: 60px;
  }
  @media(max-width: 400px) {
    width: 300px;
    padding-left: 86px;
    margin-top: 68px;
  }
}
`

export const WelcomeSection = styled.section`
  margin-top: 40px;
  margin-bottom: 40px;

  & > div > div {

  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;        
    font-family: 'Kalam', cursive;
  }

  h1 {
    font-size: 54px;
    color: #62a2c9;
    max-width: 320px;
    margin-bottom: 25px;
  }

  .h2 {
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    margin-top: 10px;
    max-width: 180px;
    font-family: Kalam, cursive;
    line-height: 1.2;
  }

  p {
    max-width: 320px;
    color: #2c2c2c;
  }

  .center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media(max-width: 1200px) {
    margin-top: 40px;

    .md {
      width: 100px !important;
      height: 100px !important;
      font-size: 50px !important;
    }
    .h2 {
      font-size: 19px;
    }
  }

  @media(max-width: 767px) {
    margin-top: 30px;

    .dots {
      margin-top: 50px;
      margin-bottom: 10px;
    }

    .md {
      width: 80px !important;
      height: 80px !important;
      font-size: 40px !important;
    }
  }

  @media(max-width: 420px) {
    .md {
      width: 60px !important;
      height: 60px !important;
      font-size: 30px !important;
    }
    .h2 {
      font-size: 14px;
    }
  }

  @media(max-width: 400px) {
    margin-top: 100px;
  }
`

export const Labels = styled.div`
  display: flex;
  flex-wrap: wrap;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
`

export const Label = styled.div`
  border: 1px solid lightgrey;
  padding: 5px 15px;
  width: fit-content;
  font-size: 12px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 12px;
  margin-bottom: 12px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    & > div {
      background: #f7f7f7;
    }
  }

  &.purple {
    color: #544bad;
    border: 1px solid #544bad;
    &:hover {
      background: #544bad;
      color: #f7f7f7;
    }
  }
  &.blue {
    color: #62a2c9;
    border: 1px solid #62a2c9;
    &:hover {
      background: #62a2c9;
      color: #f7f7f7;
    }
  }
  &.green {
    color: #62c96a;
    border: 1px solid #62c96a;
    &:hover {
      background: #62c96a;
      color: #f7f7f7;
    }
  }
  &.red {
    color: #b66060;
    border: 1px solid #b66060;
    &:hover {
      background: #b66060;
      color: #f7f7f7;
    }
  }
  &.yellow {
    color: #d7d11c;
    border: 1px solid #d7d11c;
    &:hover {
      background: #d7d11c;
      color: #f7f7f7;
    }
  }
`

export const LabelPointer = styled.div`
  width: 14px;
  height: 14px;
  background: grey;
  margin-right: 12px;
  border-radius: 10px 12px 10px 16px;

  &.purple {
    background: #544bad;
  }
  &.blue {
    background: #62a2c9;
  }
  &.green {
    background: #62c96a;
  }
  &.red {
    background: #b66060;
  }
  &.yellow {
    background: #d7d11c;
  }
`

export const CustomerReview = styled.div`
  width: 100%;
  max-width: 430px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  & > .dots {
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;
    opacity: 0.7;
  }

  & > div > div > .md {
    width: 80px;
    height: 80px;
  }
`

export const Card = styled.div`
  ${card()};
  margin-top: -20px;
  margin-bottom: 40px;
  padding: 40px;
  p {
    max-width: 100%;
    margin: 0;
  }
`