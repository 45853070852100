import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import styles, { 
  WelcomeSection,
  VideoSection,
  TopBlock,
  Labels,
  Label,
  LabelPointer,
  CustomerReview,
  Card,
} from './styles'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import Helmet from 'react-helmet'
import ebookImg from '@Images/ebook.jpg';

// layouts
import RootLayout from '@Layouts/root'

// sections

// blocks
import Arnostek from '@Blocks/arnostek'

// elements
import Shape from '@Elements/shape'

// assets
import signatureIMG from '@Images/signature-purple-mvdr.png'

const Page = styled.div`${styles}`
const PribehDetail = ({ data }) => {
  const { markdownRemark: { frontmatter: post } } = data
  const [ mediaSelected, /*setMediaSelected*/ ] = useState('youtube') // youtube | instagram | info
  const title = `${post.petName} | Psírehabilitace.cz`
  const description = post.shortDescription
  const url = `https://www.psirehabilitace.cz${data.markdownRemark.fields.slug}`
  const img = post.petAvatar.publicURL

  return (
    <RootLayout
      link={'/'}
      headerType="story-detail"
      headerContent={
        <TopBlock>
          <h1>{post.petName}</h1>
          <p>{post.shortDescription}</p>
        </TopBlock>
      }
    >
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="" />
        <meta name="image" content={img} />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={img} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="Mvdr. Dominika Bitomská" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={img} />
      </Helmet>
      <Page id="story">
        <Arnostek variant>
          <img id="pet-name-avatar" src={`${post.petAvatar.publicURL}`} alt={post.petName} />
        </Arnostek>
        <VideoSection>
          {mediaSelected === 'youtube' && (
            <iframe
              src={`https://www.youtube.com/embed/${post.youtubeSrc}`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="allowfullscreen"
            />
          )}
          {mediaSelected === 'instagram' && (
            <iframe title="instagram" width="320" height="440" src="http://instagram.com/p/Btg1jd_HJsT/embed" frameborder="0"></iframe>
          )}
          {mediaSelected === 'info' && (
            <div>todo: info content section</div>
          )}
        </VideoSection>
        <WelcomeSection>
          <Container className="rehab-period">
            <Row className="mobile-short-description">
              <Col><p>{post.shortDescription}</p></Col>
            </Row>
            <Row>
              <Col md="12" lg="6">
                <h2>Průběh terapie</h2>
                <Labels>
                  {post.dornMethod && (
                    <Link to="/dornova-metoda">
                      <Label className="purple">
                        <LabelPointer className="purple" />
                        <span>Dornova metoda</span>
                      </Label>
                    </Link>
                  )}
                  {post.magnetotherapy && (
                    <Link to="/magnetoterapie">
                      <Label className="blue">
                        <LabelPointer className="blue" />
                        <span>Magnetoterapie</span>
                      </Label>
                    </Link>
                  )}
                  {post.cupping && (
                    <Link to="/bankovani">
                      <Label className="green">
                        <LabelPointer className="green" />
                        <span>Baňkování</span>
                      </Label>
                    </Link>
                  )}
                  {post.osteodynamics && (
                    <Link to="/osteodynamika">
                      <Label className="red">
                        <LabelPointer className="red" />
                        <span>Osteodynamika</span>
                      </Label>
                    </Link>
                  )}
                  {post.homeopathy && (
                    <Link to="/homeopatie">
                      <Label className="yellow">
                        <LabelPointer className="yellow" />
                        <span>Homeopatie</span>
                      </Label>
                    </Link>
                  )}
                </Labels>
                <span style={{ color: 'grey' }}>
                  {post.longDescription}
                </span>
              </Col>
              <Col md="12" lg="6">
                <CustomerReview>
                  <Row className="dots">
                    <Col xs="4" sm="4" className="center">
                      <Shape rotation="0deg" background="#544bad" />
                    </Col>
                    <Col xs="4" sm="4" className="center">
                      <Shape rotation="40deg" background="#4c95c2" />
                    </Col>
                    <Col xs="4" sm="4" className="center">
                      <Shape rotation="80deg" background="#249da4" />
                    </Col>
                  </Row>
                  <Card>
                  <h3>Slovy páníčka</h3>
                    <p>{post.customerReview}</p>
                  </Card>
                </CustomerReview>
                <img 
                  src={signatureIMG}
                  alt="MVDr. Dominika Bitomská"
                  style={{
                    marginTop: '-105px',
                    transform: 'rotateZ(-6deg)',
                    marginBottom: 0,
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col style={{ maxWidth: '790px', marginTop: '30px' }}>
                <h3>Pokud se chcete dozvědět více o pohybovém aparátu zvířat klikněte na obrázek:</h3>
                <a href="https://dominikabitomska.cz/ebook-zdarma/">
                  <img src={ebookImg} alt="8 kroků k bezbolestbému pohybu vašeho psa" />
                </a>
              </Col>
            </Row>
          </Container>
        </WelcomeSection>
      </Page>
    </RootLayout>
  )
} 
export default PribehDetail

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        customerReview
        date
        longDescription
        petName
        shortDescription
        dornMethod
        magnetotherapy
        cupping
        osteodynamics
        homeopathy
        youtubeSrc
        petAvatar {
          publicURL
        }
      }
    }
  }
`
