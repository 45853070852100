import React from 'react'
import { Block } from './styles'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const Arnostek = ({ children, variant }) => {
  const image = useStaticQuery(graphql`
    query {
      arnostek: file(relativePath: { eq: "arnost-head.png" }) {
        childImageSharp {
          fluid(
            maxWidth: 600, 
            grayscale: false,
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <Container className="rehab-container arnostek-container">
      <Row>
        <Col>
          <Block id="arnostek-container" className={variant && 'variant'}>
            {children ? children : (
              <Img
                className="photo"
                fluid={image.arnostek.childImageSharp.fluid}
                alt="Arnoštek"
              />
            )}
          </Block>
        </Col>
      </Row>
    </Container>
  )
} 

export default Arnostek